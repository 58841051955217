import { getOrganizeURL, getListForRegionSelectURL, queryCommunityListURL } from './api'
import { communityParams } from 'common/select2Params'

// 管理范围常用列表
const organizeParams = {
  searchUrl: getOrganizeURL,
  request: {
    text: 'orgName_',
    value: 'orgId_'
  }
}

// 公司 区域类别使用的管理范围配置
const organizeParams2 = {
  searchUrl: getListForRegionSelectURL,
  request: {
    text: 'regionName',
    value: 'id'
  },
  response: {
    text: 'regionName',
    value: 'id'
  }
}

// 管理配置范围函数
const organizeParamsFun = function (item) {
  let userType = +item.userType || null;
  if ([102].includes(userType)) {
    return organizeParams2
  }
  if ([101].includes(userType)) {
    return communityParams
  }
  return organizeParams
}

// 管理范围额外参数
const organizeExtraParamsFun = function (item) {
  let treeType = +this.treeType
  let companyId = item.companyId
  let userType = +item.userType || null
  if ([102].includes(userType)) {
    switch (treeType) {
      case 1: 
      return {
        regionType: 2
      }
      case 2:
        return {
          regionType: 0,
          regionId: companyId,
          topRegionId: companyId,
      }
    }
  }
  if ([101].includes(userType)) {
    return {
      regionId: companyId
    }
  }
  return {
    roleType: userType
  }
}

export {
  organizeParamsFun,
  organizeExtraParamsFun,
}